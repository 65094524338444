<template>
  <div class="page">
    <b-card>
      <validation-observer
        ref="simpleRules"
        #default="{invalid}"
      >
        <b-form
          class="auth-forgot-password-form mt-2"
          @submit.prevent="validationForm"
        >
          <b-form-group
            label="Title"
            label-for="title"
          >
            <validation-provider
              #default="{ errors }"
              name="Title"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="title"
                  v-model="data.title"
                  :state="errors.length > 0 ? false:null"
                  class="form-control-merge"
                  name="title"
                />
              </b-input-group><small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Category"
            label-for="category"
          >
            <validation-provider
              #default="{ errors }"
              name="Category"
              rules="required"
            >
              <v-select
                id="category"
                v-model="data.category"
                name="category"
                label="text"
                :options="getCategoryOptions"
                @input="changeCategory"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Request"
            label-for="request"
          >
            <validation-provider
              #default="{ errors }"
              name="Request"
              rules="required"
            >
              <v-select
                id="request"
                v-model="data.request"
                :options="getRequestOptions"
                name="request"
                label="subcategory_name"
                :clearable="false"
                :searchable="false"
                :reduce="request => request.id"
                @input="e => changeRequeset(e)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="description"
                class="text-secondary"
              >*{{ description }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Priority"
            label-for="priority"
          >
            <validation-provider
              #default="{ errors }"
              name="Priority"
              rules="required"
            >
              <v-select
                id="priority"
                v-model="data.priority"
                :options="priorityOptions"
                name="priority"
                label="label"
                :clearable="false"
                :searchable="false"
                :reduce="priority => priority.value"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Message"
            label-for="message"
          >
            <validation-provider
              #default="{ errors }"
              name="Message"
              rules="required"
            >
              <b-form-textarea
                v-model="data.message"
                rows="8"
                :placeholder="description"
              />
              <small
                class="text-danger"
              >
                {{ errors[0] }}
              </small>
            </validation-provider>
          </b-form-group>
          <b-button
            class="mt-1"
            variant="primary"
            type="submit"
            :disabled="invalid"
          >
            Submit
          </b-button>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BCard, BButton, BFormGroup, BFormTextarea, BForm, BInputGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BButton, ValidationProvider, ValidationObserver, BFormGroup, BFormTextarea, BForm, BInputGroup, BFormInput, vSelect,
  },
  data() {
    return {
      required,
      categoryOptions: [],
      priorityOptions: [{ label: 'Critical', value: 'critical' }, { label: 'Moderate', value: 'moderate' }, { label: 'Low', value: 'low' }],
      data: {
        category: '',
        title: '',
        message: '',
        request: null,
        priority: 'moderate',
      },
      description: '',
      isPreset: false,
    }
  },
  computed: {
    getCategoryOptions() {
      return Object.keys(this.categoryOptions).map(category => {
        return category.replace(/\b\w/g, l => l.toUpperCase())
      })
    },
    getRequestOptions() {
      if (this.categoryOptions) {
        const request = this.categoryOptions[this.data.category.toLowerCase()]

        if (request && request.length && !this.isPreset) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.description = request[0].description
        }

        return request
      }
      return []
    },
  },
  created() {
    if (this.$route.params.title) {
      this.data.title = this.$route.params.title
    }
    this.getCategories()
  },
  methods: {
    changeCategory() {
      this.isPreset = false
      this.data.request = this.getRequestOptions[0].id
    },
    changeRequeset(e) {
      this.isPreset = false

      const target = this.getRequestOptions.filter(obj => {
        return obj.id === e
      })

      if (target) {
        this.description = target[0].description
      }
    },
    getCategories() {
      this.$store.dispatch('supports/getTicketCategories').then(response => {
        console.log(response)
        if (response.code === 200) {
          this.categoryOptions = response.data

          if (this.$route.params.category) {
            this.isPreset = true
            const cat = this.$route.params.category
            this.data.category = cat
            if (this.$route.params.subCategory) {
              this.isPreset = true
              const subCat = this.$route.params.subCategory
              const sub = this.categoryOptions[this.data.category.toLowerCase()]

              sub.forEach(element => {
                if (element.subcategory_slug === subCat) {
                  this.data.request = element.id
                  this.description = element.description
                }
              })
            }
          }
        }
      }).catch(err => {
        console.error(err)
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const data = {
            title: this.data.title,
            category: this.data.request,
            priority: this.data.priority,
            message: this.data.message,
          }
          this.$store.dispatch('supports/createTicket', data).then(response => {
            if (response.code === 200) {
              // window.location.href = '/support'
              this.$router.push({ name: 'Support Ticket', params: { id: response.data.id } })
            }
          }).catch(err => {
            console.error(err)
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
