var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('b-card',[_c('validation-observer',{ref:"simpleRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-forgot-password-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-form-group',{attrs:{"label":"Title","label-for":"title"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"title","state":errors.length > 0 ? false:null,"name":"title"},model:{value:(_vm.data.title),callback:function ($$v) {_vm.$set(_vm.data, "title", $$v)},expression:"data.title"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Category","label-for":"category"}},[_c('validation-provider',{attrs:{"name":"Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"category","name":"category","label":"text","options":_vm.getCategoryOptions},on:{"input":_vm.changeCategory},model:{value:(_vm.data.category),callback:function ($$v) {_vm.$set(_vm.data, "category", $$v)},expression:"data.category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Request","label-for":"request"}},[_c('validation-provider',{attrs:{"name":"Request","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"request","options":_vm.getRequestOptions,"name":"request","label":"subcategory_name","clearable":false,"searchable":false,"reduce":function (request) { return request.id; }},on:{"input":function (e) { return _vm.changeRequeset(e); }},model:{value:(_vm.data.request),callback:function ($$v) {_vm.$set(_vm.data, "request", $$v)},expression:"data.request"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.description)?_c('small',{staticClass:"text-secondary"},[_vm._v("*"+_vm._s(_vm.description))]):_vm._e()]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Priority","label-for":"priority"}},[_c('validation-provider',{attrs:{"name":"Priority","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"priority","options":_vm.priorityOptions,"name":"priority","label":"label","clearable":false,"searchable":false,"reduce":function (priority) { return priority.value; }},model:{value:(_vm.data.priority),callback:function ($$v) {_vm.$set(_vm.data, "priority", $$v)},expression:"data.priority"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Message","label-for":"message"}},[_c('validation-provider',{attrs:{"name":"Message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":"8","placeholder":_vm.description},model:{value:(_vm.data.message),callback:function ($$v) {_vm.$set(_vm.data, "message", $$v)},expression:"data.message"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-button',{staticClass:"mt-1",attrs:{"variant":"primary","type":"submit","disabled":invalid}},[_vm._v(" Submit ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }